<template>
    <div>
        <sdPageHeader :title="SaleId ? 'Edit Invoice Offer' : 'Add new Invoice Offer'">
            <template v-slot:buttons>
                <div class="page-header-actions">
                    <sdButton v-if="!saleId && $userHasPermission('Permissions.Sale.Update')" @click="handleChangeReadMode"
                        size="small" type="primary" style="gap: 5px">
                        {{ readOnlyMode ? "Edit" : "Read only" }}
                        <sdFeatherIcons :type="readOnlyMode ? 'edit' : 'eye'" size="14" />
                    </sdButton>
                    <sdButton v-if="!readOnlyMode" @click="showAddProductModal" size="small" type="primary"
                        style="gap: 5px">
                        Add Products
                        <sdFeatherIcons type="plus" size="14" />
                    </sdButton>
                    <div v-if="pdfIsLoading" style="margin-left: 8px">
                        <a-spin />
                    </div>
                    <div class="export-buttons" v-else>
                        <sdButton v-if="SaleId" size="small" type="secondary" @click="generatePdf(1)" style="gap: 5px">
                            Export PDF
                            <sdFeatherIcons type="download" />
                        </sdButton>
                        <sdButton v-if="isPdfGenerated" size="small" type="secondary" @click="generatePdf(2)"
                            style="gap: 5px">
                            Visualize PDF
                            <sdFeatherIcons type="download" />
                        </sdButton>
                    </div>
                    <sdButton size="small" type="white" @click="toggelHistory()">
                        <span>{{ $t('history') }}</span>
                        <sdFeatherIcons type="clock" />
                    </sdButton>
                </div>
            </template>
        </sdPageHeader>

        <Main>
            <a-form class="main-form" name="sDash_validation-form" ref="formRef" :rules="rules" @finish="onSubmit"
                :model="formState" :layout="formState.layout">
                <a-row :gutter="25">
                    <a-col :xs="14">
                        <sdCards :title="'Invoice details'">
                            <FormValidationWrap>
                                <HorizontalFormStyleWrap>
                                    <a-row :gutter="30">
                                        <a-col :span="12" :xs="12">
                                            <a-form-item name="salesDate" label="Date">
                                                <a-date-picker :disabled="SaleApiLoading || readOnlyMode"
                                                    v-model:value="formState.salesDate" :style="{ width: '100%' }"
                                                    :format="dateFormat" />
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="12" :xs="12">
                                            <a-form-item name="number" label="Number">
                                                <a-input class="custom" v-model:value="formState.number"
                                                    :style="{ width: '100%' }" />
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="12" :xs="12">
                                            <a-form-item :label="totalTtc<0 ? 'Partner (Ristorno)':'Partner'" name="partnerId">
                                                <a-select v-if="partnerList?.length"
                                                    :disabled="SaleApiLoading || readOnlyMode"
                                                    v-model:value="formState.partnerId" placeholder="Please choose partner"
                                                    show-search style="width: 100%" :options="partnerData"
                                                    :not-found-content="partnerFetching ? undefined : null
                                                        " :filter-option="false" @search="searchPartner">
                                                    <template v-if="partnerFetching" #notFoundContent>
                                                        <a-spin size="small" />
                                                    </template>
                                                </a-select>
                                                <a-select v-else :disabled="SaleApiLoading || readOnlyMode"
                                                    v-model:value="formState.partnerId" placeholder="Please choose partner">
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="12" :xs="12">
                                            <a-form-item name="requestedBy" label="Requested by">
                                                <a-input class="custom" v-model:value="formState.requestedBy"
                                                    :disabled="readOnlyMode" :style="{ width: '100%' }" />
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="8" :xs="8">
                                            <a-form-item name="reference" label="Reference">
                                                <a-input class="custom" v-model:value="formState.reference"
                                                    :disabled="readOnlyMode" :style="{ width: '100%' }" />
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="8" :xs="8">
                                            <a-form-item label="VAT" name="saleStatus">
                                                <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }" :min="0"
                                                    :max="100" :formatter="(value) => `${value}%`"
                                                    :parser="(value) => value.replace('%', '')"
                                                    v-model:value="formState.vat" placeholder="Value" />
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="8" :xs="8">
                                            <a-form-item label="Status" name="saleStatus">
                                                <a-select v-model:value="formState.saleStatus" :disabled="readOnlyMode">
                                                    <a-select-option :value="1"> Draft</a-select-option>
                                                    <a-select-option :value="2">
                                                        Confirmed</a-select-option>
                                                    <a-select-option :value="3">
                                                        Canceled</a-select-option>
                                                </a-select>
                                            </a-form-item>
                                        </a-col>
                                        <a-col :xs="12" :md="12">
                                            <a-form-item label="Note">
                                                <a-textarea v-model:value="formState.note" :disabled="readOnlyMode"
                                                    :style="{ width: '100%' }" placeholder="Note ..." name="address"
                                                    class="sDash_unresizable" />
                                            </a-form-item>
                                        </a-col>
                                        <a-col :xs="12" :md="12">
                                            <a-form-item label="Private Note">
                                                <a-textarea v-model:value="formState.privateNote" :disabled="readOnlyMode"
                                                    :style="{ width: '100%' }" placeholder="Private Note ..." name="address"
                                                    class="sDash_unresizable" />
                                            </a-form-item>
                                        </a-col>
                                        <a-col :span="6" :offset="canValidateInvoice ? 11 : 18" class="button-container">
                                            <sdButton htmlType="onSubmit" key="onSubmit"
                                                class="btn-outlined ant-btn-lg client-form-submit" v-if="!readOnlyMode"
                                                outlined type="primary">
                                                <span>Save</span>
                                            </sdButton>
                                        </a-col>
                                        <a-col :span="7" class="button-container" v-if="canValidateInvoice">
                                            <sdButton @click="validateInvoice" class="btn-outlined ant-btn-lg "
                                                v-if="!readOnlyMode" outlined type="primary">
                                                <span>Validate Invoice</span>
                                            </sdButton>
                                        </a-col>
                                    </a-row>
                                </HorizontalFormStyleWrap>
                            </FormValidationWrap>
                        </sdCards>
                    </a-col>
                    <a-col class="w-100" :md="10">
                        <sdCards title="Payment Detail">
                            <FormValidationWrap>
                                <HorizontalFormStyleWrap>
                                    <a-form-item label="Payment Method" name="paymentMethodId">
                                        <a-select v-model:value="formState.paymentMethodId" :disabled="readOnlyMode"
                                            placeholder="Select a payment methode">
                                            <a-select-option v-for="(payment, index) in PaymentMethodApi" :key="index"
                                                :value="payment.id">
                                                {{ payment.name }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form ref="formRefPayment" name="dynamic_form_nest_item" :model="dynamicValidateForm"
                                        @finish="onFinish" :layout="formState.layout">
                                        <a-space v-for="(payment, index) in SalePaymentTermsApi" :key="payment.id">
                                            <a-row class="payments-row">
                                                <a-col :span="24
                                                    " v-if="totalTtc > 0 && !isNaN(payment.value)"
                                                    class="payment-percent-value"><span
                                                        :title="totalTtc * (payment.value / 100)">{{ totalTtc *
                                                            (payment.value / 100)
                                                        }}</span></a-col>
                                            </a-row>
                                            <a-row justify="space-between">
                                                <a-col :span="6">
                                                    <a-form-item :name="['payments', index, 'value']" :rules="{
                                                        required: true,
                                                        message: 'Missing Modality value',
                                                    }">
                                                        <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }"
                                                            :min="0" :max="100" :formatter="(value) => `${value}%`"
                                                            :parser="(value) => value.replace('%', '')"
                                                            v-model:value="payment.value" placeholder="Value" />
                                                    </a-form-item></a-col>

                                                <a-col :span="6">
                                                    <a-form-item :name="['payments', index, 'numberOfDays']">
                                                        <a-input-number :disabled="readOnlyMode" :style="{ width: '100%' }"
                                                            :min="0" v-model:value="payment.numberOfDays"
                                                            placeholder="Days" /> </a-form-item>
                                                </a-col>

                                                <a-col :span="8">
                                                    <a-form-item :name="['payments', index, 'paymentTermOptionId']">
                                                        <a-select v-if="PaymentTermOptionApi.length"
                                                            :disabled="readOnlyMode" placeholder="Payment Term option"
                                                            v-model:value="payment.paymentTermOptionId">
                                                            <a-select-option
                                                                v-for="(paymentTerm, index) in PaymentTermOptionApi"
                                                                :key="index" :value="paymentTerm.id">
                                                                {{ paymentTerm.name }}</a-select-option>
                                                        </a-select>
                                                        <a-select v-else :disabled="readOnlyMode"
                                                            placeholder="Payment Term option"></a-select>
                                                    </a-form-item></a-col>
                                                <a-col :span="3" class="payment-buttons" v-if="!readOnlyMode">
                                                    <sdFeatherIcons @click="addModality(payment)" type="check-circle" />
                                                    <sdFeatherIcons @click="removePayment(payment)" type="minus-circle" />
                                                </a-col>
                                            </a-row>
                                        </a-space>
                                        <a-form-item>
                                            <a-button type="dashed" block @click="addPayment" v-if="!readOnlyMode">
                                                <PlusOutlined />
                                                Add payment
                                            </a-button>
                                        </a-form-item>
                                    </a-form>
                                </HorizontalFormStyleWrap>
                            </FormValidationWrap>
                        </sdCards>
                    </a-col>
                </a-row>
            </a-form>
            <a-row :gutter="25">
                <a-col :xs="24">
                    <sdCards title="Products" caption="Products">
                        <a-table :columns="columns" :dataSource="productsData" :loading="isSaleProductLoading" bordered>
                            <template #price="{ record }">
                                <div>{{ record.price }} {{ record.currency }}</div>
                            </template>
                            <template #total="{ record }">
                                <div>
                                    {{ record.quantity * record.price }} {{ record.currency }}
                                </div>
                            </template>
                            <template #operation="{ record }">
                                <div class="editable-row-operations">
                                    <span>
                                        <a v-if="!readOnlyMode">
                                            <sdFeatherIcons @click="showEditProductModal(record)" type="edit" />
                                        </a>
                                        <a v-if="!readOnlyMode" @click="deleteFromSale(record)">
                                            <sdFeatherIcons type="trash" />
                                        </a>
                                    </span>
                                </div>
                            </template>
                            <template #footer>
                                <div class="table-footer">
                                    Total NET : {{ totalNet }}
                                </div>
                                <div class="table-footer">
                                    Total VAT : {{ totalVat.toFixed(2) }}
                                </div>
                                <div class="table-footer">
                                    Total TTC : {{ totalTtc }}
                                </div>
                            </template>
                        </a-table>
                    </sdCards>
                </a-col>
            </a-row>
            <a-row :gutter="25">
                <a-col :xs="24">
                    <AuxiliaryCharge ref="auxiliaryChargeCmp" @chargeUpdated="checkValidty" parentType="1"
                        :readOnlyMode="readOnlyMode" :parentId="SaleId">
                    </AuxiliaryCharge>
                </a-col>
            </a-row>
        </Main>
        <HistoryModal v-if="showHistoryModal" entity="Sale" :entityId="SaleId" @Cancel="toggelHistory">
        </HistoryModal>
        <AddProductModal :dealId="DealId" :partnerId="formState.partnerId" :productsIds="productsIds"
            @hideAddProductModal="hideAddProductModal" @SelectedProduct="AddSelectedProduct" v-if="showAddProduct">
        </AddProductModal>
        <EditProductModal :product="currentProduct" :productId="EditProductId" :saleId="SaleId"
            @hideEditProductModal="hideEditProductModal" @updateProduct="updateProduct"
            v-if="showEditProduct && currentProduct"></EditProductModal>
    </div>
</template>

<script>
import {
    FormValidationWrap,
    HorizontalFormStyleWrap,
} from "../forms/overview/Style";
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { Main, TableWrapper } from "../styled";
import { useStore } from "vuex";
import { useProduct } from "@/composable/useProduct";
import {
    MinusCircleOutlined,
    CheckCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons-vue";

import { notification, Modal } from "ant-design-vue";
import { DataService } from "@/config/dataService/dataService";
import {
    computed,
    onMounted,
    reactive,
    ref,
    watchEffect,
    getCurrentInstance,
    nextTick,
    defineAsyncComponent,
    watch
} from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import moment from "moment";
import usePartnerSearch from "../../composable/usePartnerSearch";

const AddProductModal = defineAsyncComponent(() =>
    import("@/view/product/AddProductModal")
);
const EditProductModal = defineAsyncComponent(() =>
    import("@/view/product/EditProductModal")
);
const AuxiliaryCharge = defineAsyncComponent(() => import("@/components/businessComponents/AuxiliaryCharge.vue"));
const HistoryModal = defineAsyncComponent(() => import("@/components/businessComponents/HistoryModal.vue"));

const dateFormat = "YYYY/MM/DD";
const todayDate = moment();

const columns = [
    {
        title: "Description",
        dataIndex: "description",
        width: "20%",
    },
    {
        title: "Condition",
        dataIndex: "conditionName",
        width: "15%",
    },
    {
        title: "Unit Price",
        dataIndex: "price",
        width: "20%",
        slots: {
            customRender: "price",
        },
    },
    {
        title: "Quantity",
        dataIndex: "quantity",
        width: "10%",
    },
    {
        title: "Total Price",
        dataIndex: "total",
        width: "15%",

        slots: {
            customRender: "total",
        },
    },
    {
        title: "Operation",
        dataIndex: "operation",
        width: "15%",
        slots: {
            customRender: "operation",
        },
    },
];
const data = [];

const Sale = {
    name: "Sale",
    components: {
        RecordViewWrapper,
        Main,
        TableWrapper,
        FormValidationWrap,
        HorizontalFormStyleWrap,
        HistoryModal,
        AddProductModal,
        EditProductModal,
        AuxiliaryCharge,
        MinusCircleOutlined,
        PlusOutlined,
        CheckCircleOutlined,
    },
    setup() {
        const { state, dispatch } = useStore();
        const { formatProductToList } = useProduct();
        const { params } = useRoute();
        const appContext = getCurrentInstance().appContext;
        const userHasPermission = appContext.config.globalProperties.$userHasPermission;
        const partnerApi = computed(() => state.partnerApi.data);
        const partnerApiLoading = computed(() => state.partnerApi.loading);
        const saleIsValid = computed(() => state.saleApi.saleIsValid);
        const SaleApi = computed(() => state.saleApi);
        const SalePaymentTermsApi = computed(() => state.SalePaymentTermsApi.data);
        const PaymentTermOptionApi = computed(() => state.PaymentTermOptionApi.data);
        const saleNumber = computed(() => state.saleApi.saleNumber);
        const SaleApiLoading = computed(() => state.saleApi.loading);
        const PaymentMethodApi = computed(() => state.PaymentMethodApi.data);
        const isSaleProductLoading = computed(() => state.productSaleApi.loading);
        const productSaleApi = computed(() => state.productSaleApi.data);
        const canValidateInvoice = computed(() => userHasPermission('Permissions.Sale.CanValidate') && !saleIsValid.value);
        const selectedProducts = ref(data);
        const auxiliaryChargeCmp = ref(null);
        const auxiliaryTotalCharge = computed(() => {
            return auxiliaryChargeCmp.value?.totalCharge;
        });
        let DealId = ref("");
        let isPdfGenerated = ref(false);
        let pdfIsLoading = ref(false);
        let DealIsPartialSale = ref(true);
        let productCountInDeal = ref(0);
        const editableData = reactive({});

        var currentSaleNumber = ref("");
        var formState = reactive({
            layout: "vertical",
            partnerId: null,
            number: null,
            requestedBy: "",
            reference: "",
            vat: 0,
            saleType: 3,
            saleStatus: 1,
            paymentMethodId: null,
            salesDate: todayDate,
            note: "",
            privateNote: "",
        });

        const formRef = ref();
        //const showAddProduct = ref(false);

        var readOnlyMode = ref(true);

        const handleChangeReadMode = () => {
            return readOnlyMode.value = !readOnlyMode.value
        };


        const rules = {
            salesDate: [
                {
                    type: "object",
                    required: true,
                    message: "This field is required",
                    trigger: "change",
                },
            ],
            number: [
                {
                    required: true,
                    message: "This field is required",
                    trigger: "change",
                },
            ],
            partnerId: [
                {
                    required: true,
                    message: "This field is required",
                    trigger: "change",
                },
            ],
        };
        let saleProductLength = computed(
            () =>
                productSaleApi.value &&
                productSaleApi.value.length &&
                productSaleApi.value.length
        );

        onMounted(() => {
            dispatch("partnerInfo");
            dispatch("paymentMethodInfo");
            dispatch("emptySale");
            dispatch("paymentTermOptionGetAllData");

            nextTick();
            if (params.id) {
                dispatch("singleSaleGetData", params.id);
                dispatch("salePaymentTermsGetData", params.id);
                dispatch("auxiliaryChargeGetData", `SaleId=${params.id}`);

                SaleId.value = params.id;
            } else {
                readOnlyMode.value = false;
                const generateData = {
                    saleType: 1,
                    Year: moment().year(),
                };
                dispatch("saleGenerateNumber", generateData);
            }

            if (productSaleApi.value && productSaleApi.value.length) {
                productCountInDeal.value = productSaleApi.value[0].productCountInDeal;
                DealIsPartialSale.value =
                    productSaleApi.value[0].dealIsPartialSale == 2;
                DealId.value = productSaleApi.value[0].dealId;

            }
        });

        const productsData = computed(() => {
            if (productSaleApi.value && productSaleApi.value.length) {
                return productSaleApi.value.map((product, key) => {
                    const {
                        productId,
                        countryName,
                        unitPrice,
                        saleId,
                        currency,
                        isPartialSale,
                        condition,
                        conditionName,
                        quantityVirtuelle,
                        quantityInStock,
                        description,
                        quantityInitial,
                        unitOfMeasureId,
                        unitOfMeasureName,
                        deliveryTime,
                        productType,
                        numberPackage,
                        totalPrice,
                        availability,
                        currencyId,
                        currencyName,
                        incotermId,
                        incotermName,
                        stockLocation,
                        stockLocationMaps,
                        countryId,
                        originCountryId,
                        originCountryName,
                        categoryId,
                        categoryName,
                        sizeId,
                        sizeName,
                        gradeId,
                        gradeName,
                        weightId,
                        weightName,
                        rangeId,
                        rangeName,
                        threadId,
                        threadName,
                        hsCodeId,
                        hsCodeName,
                        note,
                        quantity,
                        pslId,
                        drift,
                    } = product;

                    return {
                        key: key + 1,
                        id: productId,
                        productId: productId,
                        description,
                        condition: condition,
                        country: countryName,
                        saleId: saleId,
                        currency,
                        conditionName,
                        isPartialSale,
                        priceAndCurrency: `${unitPrice} ${currency}`,
                        price: product.price ?? unitPrice,
                        unitPrice: product.price ?? unitPrice,
                        quantity: quantity,
                        quantityVirtuelle,
                        quantityInStock,
                        quantityInitial,
                        unitOfMeasureId,
                        unitOfMeasureName,
                        deliveryTime,
                        productType,
                        numberPackage,
                        totalPrice,
                        availability,
                        currencyId,
                        currencyName,
                        incotermId,
                        incotermName,
                        stockLocation,
                        stockLocationMaps,
                        countryId,
                        categoryId,
                        categoryName,
                        sizeId,
                        sizeName,
                        gradeId,
                        gradeName,
                        originCountryId,
                        originCountryName,
                        weightId,
                        weightName,
                        rangeId,
                        rangeName,
                        threadId,
                        threadName,
                        hsCodeId,
                        hsCodeName,
                        note,
                        pslId,
                        drift,
                    };
                });
            } else {
                return [];
            }
        });

        const productsIds = computed(() =>
            productSaleApi.value && productSaleApi.value.length
                ? productSaleApi.value.map((product) => product.id)
                : []
        );

        const partnerList = computed(() => {
            return partnerApi.value;
        });
        const SaleId = computed(() => {
            if (SaleApi.value) {
                return SaleApi.value.saleId;
            }
            return null;
        });

        const onSubmit = () => {
            formRef.value
                .validate()
                .then(async () => {
                    formState.totalNet = totalCost;
                    if (!SaleId.value) {
                        formState.products = productsData.value;
                        dispatch("saleSubmitData", { formState });
                    } else {
                        delete formState.products;
                        dispatch("saleUpdateData", { formState });
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });
        };
        const fillAllProducts = (dealId, partnerId = "") => {
            if (!DealId.value) {
                DealId.value = dealId;
            }
            if (!formState.partnerId) {
                formState.partnerId = partnerId;
            }
            dispatch("addAllDealProductsToSale", dealId);
            hideAddProductModal(false);
        };
        const totalCost = ref(0);
        const calculateTotalCost = () => {
            let totalCostSum = 0;
            for (let i = 0; i < productsData.value.length; i++) {
                const product = productsData.value[i];
                const quantity = product.quantity;
                const unitPrice = product.price ?? product.unitPrice;

                totalCostSum += quantity * unitPrice;
            }

            totalCost.value = totalCostSum;
        };

        const deleteFromSale = (product) => {
            const confirm = window.confirm("Are you sure delete this?");
            if (confirm) {
                if (SaleId.value) {
                    product.saleId = SaleId.value;
                    product.productId = product.id;
                    dispatch("saleProductApiDataDelete", product);
                } else {
                    dispatch("saleProductDataDelete", product.productId);
                }
            }
            return false;
        };

        // Deal realted logic
        // #region Edit Product
        const EditProductId = ref(null);
        var currentProduct = reactive({});

        const showEditProduct = ref(false);

        const showEditProductModal = (product) => {
            Object.assign(currentProduct, product);
            EditProductId.value = product.productId;
            showEditProduct.value = true;
        };
        const hideEditProductModal = (value) => {
            showEditProduct.value = value;
            currentProduct.value = {};
        };
        const updateProduct = (formState) => {
            if (SaleId.value) {
                dispatch("productSaleApiUpdateData", formState);
            } else {
                dispatch("productSaleSimpleUpdateData", formState);
            }
            showEditProduct.value = false;
        };

        // #region Product
        const showAddProduct = ref(false);

        const showAddProductModal = () => {
            showAddProduct.value = true;
        };
        const hideAddProductModal = (value) => {
            showAddProduct.value = value;
        };
        const AddSelectedProduct = (product) => {
            if (SaleId.value) {
                product.productId = product.id;
                product.saleId = SaleId.value;
                dispatch("saleProductSubmitData", product);
            } else {
                if (!DealId.value) {
                    DealId.value = product.dealId;
                }
                if (!formState.partnerId) {
                    formState.partnerId = product.partnerId;
                }
                productSaleApi.value.push(formatProductToList(product));
            }

            if (product.deal.partialSale == 2 && productCountInDeal.value - 1 == 0) {
                DealIsPartialSale.value = true;

                if (SaleId.value) {
                    product.productId = product.id;
                    product.saleId = SaleId.value;
                    dispatch("saleProductSubmitData", product);
                } else {
                    if (
                        !DealIsPartialSale.value &&
                        saleProductLength.value + 1 != productCountInDeal.value
                    ) {
                        Modal.warning({
                            title: "Partial Sale is not possible for this Quotation. ",
                            content:
                                "You need to add all products from this quotation to proceed with your order.",
                            okText: "Add all quotation products",
                            closable: true,
                            onOk() {
                                fillAllProducts(product.dealId, product.partnerId);
                            },
                            cancelText: "I'am aware",
                            onCancel() {
                                productSaleApi.value.push(formatProductToList(product));
                            },
                        });
                    } else {
                        productSaleApi.value.push(formatProductToList(product));
                    }
                }
            } else {
                DealIsPartialSale.value = product.deal.partialSale == 2;
            }
        };
        // #endregion

        const formRefPayment = ref();
        const dynamicValidateForm = reactive({
            payments: [],
        });
        const removePayment = (item) => {
            if (item.id) {
                dispatch("salePaymentTermsApiDataDelete", item);
            } else {
                let index = SalePaymentTermsApi.value.findIndex(
                    (element) => element.index === item.index
                );
                if (index !== -1) {
                    SalePaymentTermsApi.value.splice(index, 1);
                }
            }
        };
        const addPayment = () => {
            formRefPayment.value
                .validate()
                .then(async () => {
                    SalePaymentTermsApi.value.push({
                        saleId: SaleId.value,
                        parentType: 1,
                        index: SalePaymentTermsApi.value.length,
                        id: undefined,
                    });
                })
                .catch((error) => {
                    console.log("error", error);
                });
        };
        const addModality = (item) => {
            formRefPayment.value
                .validate()
                .then(async () => {
                    if (item.id) {
                        dispatch("salePaymentTermsUpdateData", item);
                    } else {
                        dispatch("salePaymentTermsSubmitData", item);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                })
                .finally(() => {
                    onSubmit();
                    checkValidty();
                });
        };

        dynamicValidateForm.payments = computed(() =>
            SalePaymentTermsApi.value && SalePaymentTermsApi.value.length
                ? SalePaymentTermsApi.value.map((paymentTerm, key) => {
                    return {
                        key: key + 1,
                        id: paymentTerm.id,
                        value: paymentTerm.value,
                        detail: paymentTerm.detail,
                    };
                })
                : []
        );

        // #endregion
        onBeforeRouteLeave(() => {
            dispatch("emptyProductSaleData");
            dispatch("emptySale");
            formState.partnerId = "";
        });

        /// watch
        watchEffect(() => {
            if (
                SaleApi.value &&
                SaleApi.value.saleId &&
                partnerApi.value &&
                partnerApi.value.length
            ) {
                formState.id = SaleApi.value.data.id;
                formState.salesDate = moment(SaleApi.value.data.salesDate);
                formState.number = SaleApi.value.data.number;
                formState.requestedBy = SaleApi.value.data.requestedBy;
                formState.reference = SaleApi.value.data.reference;
                formState.saleStatus = SaleApi.value.data.saleStatus;
                formState.vat = SaleApi.value.data.vat;
                isPdfGenerated.value = SaleApi.value.data.isPdfGenerated;
                formState.paymentMethodId = SaleApi.value.data.paymentMethodId;
                formState.partnerId = SaleApi.value.data.partnerId;
                formState.note = SaleApi.value.data.note;
                formState.privateNote = SaleApi.value.data.privateNote;
                if (partnerData.value.length < 1) {
                    partnerData.value.push({
                        value: SaleApi.value.data.partnerId,
                        label: SaleApi.value.data.partnerName,
                    });
                }
            }

            if (productSaleApi.value && productSaleApi.value.length) {
                productCountInDeal.value = productSaleApi.value[0].productCountInDeal;
                DealIsPartialSale.value =
                    productSaleApi.value[0].dealIsPartialSale == 2;
                DealId.value = productSaleApi.value[0].dealId;

                calculateTotalCost();
            }

            if (saleNumber.value) {
                formState.number = saleNumber.value;
            }
        });

        const generatePdf = async (type) => {
            try {
                var response;
                pdfIsLoading.value = true;
                if (type == 1) {
                    response = await DataService.getFile(
                        "api/Sale/GeneratePDF?SaleId=" + SaleId.value
                    );
                    isPdfGenerated.value = true;
                } else {
                    response = await DataService.getFile(
                        "api/Sale/GetLastGeneratedPdf?SaleId=" + SaleId.value
                    );
                }

                if (response) {
                    var byteCharacters = response;
                    var blob = new Blob([byteCharacters], { type: "application/pdf" });
                    var url = URL.createObjectURL(blob);
                    window.open(url, "_blank");
                }
            } catch (err) {
                console.log("error", err);
                notification.error({
                    message: "Error while downloading PDF",
                });
            } finally {
                pdfIsLoading.value = false;
            }
        };
        // Partner logic 
        const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
            formState.partnerId
        );
        const showHistoryModal = ref(false);
        const toggelHistory = () => {
            showHistoryModal.value = !showHistoryModal.value;
        }
        /// Calculation 
        const totalNet = computed(() => totalCost.value + auxiliaryTotalCharge.value)
        const totalVat = computed(() => totalNet.value * (Number(formState.vat) / 100))
        const totalTtc = computed(() => totalNet.value + totalVat.value)

        watch(totalTtc, () => {
            if (SaleId.value) {
                checkValidty();
            }
        });

        watch(saleIsValid, (newValue, oldValue) => {
            if (newValue != oldValue) {
                dispatch("singleSaleOnlyGetData", params.id);
            }
        });

        const validateInvoice = () => {
            dispatch("validateInvoice", SaleId.value)
        }
        const checkValidty = () => {
            dispatch("getSaleGetValidity", SaleId.value);
        }
        return {
            checkValidty,
            validateInvoice,
            showHistoryModal,
            toggelHistory,
            partnerData,
            searchPartner,
            partnerFetching,
            formRef,
            rules,
            onSubmit,
            columns,
            formState,
            partnerApiLoading,
            partnerList,
            partnerApi,
            SaleApi,
            SaleId,
            productsData,
            SaleApiLoading,
            dateFormat,
            selectedProducts,
            editableData,
            editingKey: "",
            showAddProductModal,
            hideAddProductModal,
            showAddProduct,
            AddSelectedProduct,
            deleteFromSale,
            isSaleProductLoading,
            productSaleApi,
            productsIds,
            currentSaleNumber,
            params,
            DealIsPartialSale,
            saleProductLength,
            DealId,
            totalCost,
            fillAllProducts,
            generatePdf,
            showEditProduct,
            showEditProductModal,
            EditProductId,
            updateProduct,
            hideEditProductModal,
            currentProduct,
            isPdfGenerated,
            pdfIsLoading,
            handleChangeReadMode,
            readOnlyMode,
            SalePaymentTermsApi,
            PaymentMethodApi,
            formRefPayment,
            dynamicValidateForm,
            removePayment,
            addPayment,
            addModality,
            PaymentTermOptionApi,
            auxiliaryChargeCmp,
            auxiliaryTotalCharge,
            totalNet,
            totalVat,
            totalTtc,
            canValidateInvoice,
        };
    },
};

export default Sale;
</script>
<style lang="scss">
#app {
    .ant-upload {
        width: 100%;
    }

    .custom,
    .ant-calendar-picker-input.ant-input {
        height: 38px;
    }

    .button-container {
        align-self: center;

        .ant-btn {
            height: 38px;
            width: 100%;
        }
    }

    .editable-row-operations a {
        margin-right: 8px;
    }

    .table-footer {
        text-align: right;
        font-size: 15px;
        font-weight: bold;
    }

    .payment-buttons {
        display: flex;
        justify-content: space-between;
        padding-top: 17px;
    }

    .payment-percent-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        padding-top: 14px;
    }
}
</style>
